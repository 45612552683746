import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { ConfigurationDTO } from '@reactivereality/cs-api-sdk';
import { Subject, takeUntil } from 'rxjs';
import { PRODUCT_TYPE } from '../../data';
import { SceneDTO } from '../../data/api';
import { ModalService } from '../../modal/modal.service';
import { CreateRequestModalStore } from '../../request-overview/components/create-request-modal/create-request-modal.state';
import {
  GarmentThumbnailPipe,
  ProductTypePipe,
  SceneThumbnailPipe,
} from '../../utils/CommonPipes';
import { AddItemComponent } from '../add-item/add-item.component';
import { AddSceneModalComponent } from './add-scene-modal/add-scene-modal.component';
import { SceneSelectionService } from './scene-selection.service';

@Component({
  selector: 'app-scene-selection-entry',
  templateUrl: './scene-selection-entry.component.html',
  styleUrls: ['./scene-selection-entry.component.scss'],
  imports: [
    CommonModule,
    AddItemComponent,
    GarmentThumbnailPipe,
    SceneThumbnailPipe,
    ProductTypePipe,
    AddSceneModalComponent,
  ],
  standalone: true,
})
export class SceneSelectionEntryComponent implements OnDestroy {
  protected ngUnsubscribe: Subject<void> = new Subject<void>();
  public sceneType: PRODUCT_TYPE.SCENE;
  @Input() public selectedScene: SceneDTO;
  @Output() public changeClicked = new EventEmitter<ConfigurationDTO>();
  @ViewChild('selectScene') selectScene: TemplateRef<any>;

  constructor(
    public createRequestModalStore: CreateRequestModalStore,
    public modalService: ModalService,
    public sceneSelectionService: SceneSelectionService,
  ) {
    this.sceneSelectionService.onSelect
      .pipe(takeUntil(this.ngUnsubscribe))
      .subscribe(() => {
        this.modalService.show({
          title: 'Select Scene',
          template: this.selectScene,
        });
      });
  }

  ngOnDestroy(): void {
    this.ngUnsubscribe.next();
    this.ngUnsubscribe.complete();
  }

  public onSceneSelected($event: SceneDTO) {
    this.createRequestModalStore.setScene($event);
  }
}
