<div class="header {{ visible ? 'mb-3' : '' }}">
  <h2>
    {{ title }}
  </h2>
  <div
    class="loader"
    title="loading..."
    [hidden]="(loading$ | async) === false"
  ></div>
  <button
    class="btn btn-outline-primary"
    (click)="openClicked()"
    [hidden]="loading$ | async"
  >
    {{ visible ? 'Close' : 'Edit' }}
  </button>
</div>
<div
  cdkDropList
  [hidden]="!visible"
  (cdkDropListDropped)="drop($event)"
  [cdkDropListAutoScrollStep]="20"
  [cdkDropListAutoScrollDisabled]="false"
>
  <span *ngIf="references?.length === 0"><b>No items selected...</b></span>
  <div *ngFor="let reference of references; trackBy: identify" cdkDrag>
    <app-outfit-list-row
      [reference]="reference"
      (removeReference)="this.removeReference($event)"
      (moveReferenceUp)="this.moveRefUp.emit($event)"
      (moveReferenceDown)="this.moveRefDown.emit($event)"
    >
    </app-outfit-list-row>
  </div>
</div>
